'use client';

import clsx from 'clsx';
import { useSession } from 'next-auth/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAddFavoriteMutation,
  useGetFavoritesQuery,
  useRemoveFavoriteMutation
} from '@akinon/next/data/client/wishlist';
import { LoaderSpinner } from '@theme/components';
import { twMerge } from 'tailwind-merge';
import { pushAddToWishlist } from '@theme/utils/gtm';
import { IProduct } from '@theme/types';

interface FavButtonProps {
  label?: string;
  size?: number;
  className?: string;
  iconColor?: string;
  iconColorActive?: string;
  iconColorHover?: string;
  iconColorHoverFill?: string;
  labelClassName?: string;
}

const useFavButton = (productPk: number, isParentHovered = false) => {
  const { status } = useSession();

  const { data: favorites, isLoading } = useGetFavoritesQuery(
    {
      limit: 1000
    },
    { skip: status !== 'authenticated' }
  );

  const favoriteItem = useMemo(
    () =>
      favorites?.results?.find(
        (item) => Number(item.product.pk) === Number(productPk)
      ),
    [favorites, productPk]
  );

  const [isActive, setIsActive] = useState(Boolean(favoriteItem));
  const [isEventTriggered, setIsEventTriggered] = useState(false);

  const [addFavorite, { status: addFavoriteStatus }] = useAddFavoriteMutation();
  const [removeFavorite, { status: removeFavoriteStatus }] =
    useRemoveFavoriteMutation();

  const handleClick = useCallback(() => {
    if (favoriteItem) {
      removeFavorite(favoriteItem.pk);
      setIsEventTriggered(false);
    } else {
      addFavorite(productPk);
      setIsEventTriggered(true);
    }
  }, [favoriteItem, productPk, addFavorite, removeFavorite]);

  useEffect(() => {
    setIsActive(Boolean(favoriteItem));
  }, [favoriteItem]);

  useEffect(() => {
    if (isEventTriggered && favoriteItem) {
      const product = favorites?.results?.find(
        (item) => Number(item.product.pk) === Number(productPk)
      )?.product;

      pushAddToWishlist(product as unknown as IProduct);
      setIsEventTriggered(false);
    }
  }, [favoriteItem, isEventTriggered]);

  const FavButton = useMemo(() => {
    const View = (props: FavButtonProps) => {
      const [isFavoriteIconHovered, setIsFavoriteIconHovered] = useState(false);

      const renderFavoriteIcon = (className, classNameEmptyHovered) => {
        if (isParentHovered && !isFavoriteIconHovered && !isActive) {
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classNameEmptyHovered}
            >
              <path
                opacity="0.2"
                d="M20.3775 12.5031L12 21.0006L3.62251 12.5031C2.74331 11.6239 2.24939 10.4315 2.24939 9.18812C2.24939 7.94475 2.74331 6.75231 3.62251 5.87312C4.5017 4.99393 5.69414 4.5 6.93751 4.5C8.18087 4.5 9.37331 4.99393 10.2525 5.87312L12 7.50062L13.7475 5.87312C14.1828 5.43779 14.6997 5.09246 15.2684 4.85686C15.8372 4.62126 16.4469 4.5 17.0625 4.5C17.6782 4.5 18.2878 4.62126 18.8566 4.85686C19.4254 5.09246 19.9422 5.43779 20.3775 5.87312C20.8128 6.30845 21.1582 6.82526 21.3938 7.39405C21.6294 7.96284 21.7506 8.57247 21.7506 9.18812C21.7506 9.80377 21.6294 10.4134 21.3938 10.9822C21.1582 11.551 20.8128 12.0678 20.3775 12.5031Z"
                fill="#5d0024"
              />
              <path
                d="M20.9062 5.3445C19.8873 4.32811 18.5074 3.75655 17.0682 3.75479C15.629 3.75303 14.2477 4.32123 13.2262 5.33512L12 6.47419L10.7728 5.33137C9.75164 4.31307 8.36777 3.74212 6.92565 3.74415C5.48352 3.74617 4.10127 4.32099 3.08296 5.34216C2.06465 6.36332 1.49371 7.74718 1.49573 9.18931C1.49775 10.6314 2.07258 12.0137 3.09374 13.032L11.4703 21.5314C11.5401 21.6022 11.6233 21.6585 11.715 21.6969C11.8068 21.7353 11.9052 21.7551 12.0047 21.7551C12.1041 21.7551 12.2026 21.7353 12.2943 21.6969C12.3861 21.6585 12.4693 21.6022 12.5391 21.5314L20.9062 13.032C21.9253 12.0124 22.4977 10.6298 22.4977 9.18825C22.4977 7.74669 21.9253 6.36414 20.9062 5.3445ZM19.8422 11.9782L12 19.932L4.15312 11.9707C3.41465 11.2323 2.99979 10.2307 2.99979 9.18637C2.99979 8.14203 3.41465 7.14046 4.15312 6.402C4.89158 5.66354 5.89315 5.24867 6.93749 5.24867C7.98184 5.24867 8.9834 5.66354 9.72187 6.402L9.74062 6.42075L11.4891 8.04731C11.6278 8.17647 11.8104 8.24827 12 8.24827C12.1896 8.24827 12.3721 8.17647 12.5109 8.04731L14.2594 6.42075L14.2781 6.402C15.0171 5.66403 16.0189 5.24985 17.0633 5.25055C18.1076 5.25125 19.1089 5.66679 19.8469 6.40575C20.5848 7.14471 20.999 8.14656 20.9983 9.1909C20.9976 10.2352 20.5821 11.2365 19.8431 11.9745L19.8422 11.9782Z"
                fill="#5d0024"
              />
            </svg>
          );
        } else if (isParentHovered && !isActive && isFavoriteIconHovered) {
          return (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={className}
            >
              <path
                d="M20.9062 5.3445C19.8873 4.32811 18.5074 3.75655 17.0682 3.75479C15.629 3.75303 14.2477 4.32123 13.2262 5.33512L12 6.47419L10.7728 5.33137C9.75164 4.31307 8.36777 3.74212 6.92565 3.74415C5.48352 3.74617 4.10127 4.32099 3.08296 5.34216C2.06465 6.36332 1.49371 7.74718 1.49573 9.18931C1.49775 10.6314 2.07258 12.0137 3.09374 13.032L11.4703 21.5314C11.5401 21.6022 11.6233 21.6585 11.715 21.6969C11.8068 21.7353 11.9052 21.7551 12.0047 21.7551C12.1041 21.7551 12.2026 21.7353 12.2943 21.6969C12.3861 21.6585 12.4693 21.6022 12.5391 21.5314L20.9062 13.032C21.9253 12.0124 22.4977 10.6298 22.4977 9.18825C22.4977 7.74669 21.9253 6.36414 20.9062 5.3445ZM19.8422 11.9782L12 19.932L4.15312 11.9707C3.41465 11.2323 2.99979 10.2307 2.99979 9.18637C2.99979 8.14203 3.41465 7.14046 4.15312 6.402C4.89158 5.66354 5.89315 5.24867 6.93749 5.24867C7.98184 5.24867 8.9834 5.66354 9.72187 6.402L9.74062 6.42075L11.4891 8.04731C11.6278 8.17647 11.8104 8.24827 12 8.24827C12.1896 8.24827 12.3721 8.17647 12.5109 8.04731L14.2594 6.42075L14.2781 6.402C15.0171 5.66403 16.0189 5.24985 17.0633 5.25055C18.1076 5.25125 19.1089 5.66679 19.8469 6.40575C20.5848 7.14471 20.999 8.14656 20.9983 9.1909C20.9976 10.2352 20.5821 11.2365 19.8431 11.9745L19.8422 11.9782Z"
                fill="black"
              />
            </svg>
          );
        } else {
          return isActive ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={className}
            >
              <path
                d="M22.5 9.18754C22.5016 9.90177 22.3616 10.6092 22.088 11.269C21.8144 11.9288 21.4128 12.5277 20.9062 13.0313L12.5344 21.5269C12.4646 21.5978 12.3814 21.6541 12.2897 21.6925C12.1979 21.7309 12.0994 21.7507 12 21.7507C11.9005 21.7507 11.8021 21.7309 11.7103 21.6925C11.6186 21.6541 11.5354 21.5978 11.4656 21.5269L3.09374 13.0313C2.07307 12.0119 1.49917 10.6287 1.49829 9.18617C1.49741 7.7436 2.06963 6.35977 3.08905 5.3391C4.10848 4.31843 5.49161 3.74453 6.93418 3.74365C8.37674 3.74277 9.76057 4.31499 10.7812 5.33441L12 6.47348L13.2272 5.33066C13.9888 4.57278 14.958 4.05754 16.0122 3.85001C17.0665 3.64247 18.1586 3.75193 19.1507 4.16458C20.1428 4.57722 20.9904 5.27455 21.5865 6.16853C22.1826 7.0625 22.5005 8.11305 22.5 9.18754Z"
                fill="#5d0024"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={className}
            >
              <path
                d="M20.9062 5.3445C19.8873 4.32811 18.5074 3.75655 17.0682 3.75479C15.629 3.75303 14.2477 4.32123 13.2262 5.33512L12 6.47419L10.7728 5.33137C9.75164 4.31307 8.36777 3.74212 6.92565 3.74415C5.48352 3.74617 4.10127 4.32099 3.08296 5.34216C2.06465 6.36332 1.49371 7.74718 1.49573 9.18931C1.49775 10.6314 2.07258 12.0137 3.09374 13.032L11.4703 21.5314C11.5401 21.6022 11.6233 21.6585 11.715 21.6969C11.8068 21.7353 11.9052 21.7551 12.0047 21.7551C12.1041 21.7551 12.2026 21.7353 12.2943 21.6969C12.3861 21.6585 12.4693 21.6022 12.5391 21.5314L20.9062 13.032C21.9253 12.0124 22.4977 10.6298 22.4977 9.18825C22.4977 7.74669 21.9253 6.36414 20.9062 5.3445ZM19.8422 11.9782L12 19.932L4.15312 11.9707C3.41465 11.2323 2.99979 10.2307 2.99979 9.18637C2.99979 8.14203 3.41465 7.14046 4.15312 6.402C4.89158 5.66354 5.89315 5.24867 6.93749 5.24867C7.98184 5.24867 8.9834 5.66354 9.72187 6.402L9.74062 6.42075L11.4891 8.04731C11.6278 8.17647 11.8104 8.24827 12 8.24827C12.1896 8.24827 12.3721 8.17647 12.5109 8.04731L14.2594 6.42075L14.2781 6.402C15.0171 5.66403 16.0189 5.24985 17.0633 5.25055C18.1076 5.25125 19.1089 5.66679 19.8469 6.40575C20.5848 7.14471 20.999 8.14656 20.9983 9.1909C20.9976 10.2352 20.5821 11.2365 19.8431 11.9745L19.8422 11.9782Z"
                fill="black"
              />
            </svg>
          );
        }
      };

      if (
        addFavoriteStatus === 'pending' ||
        removeFavoriteStatus === 'pending' ||
        isLoading
      ) {
        return (
          <LoaderSpinner
            className="w-6 h-6 ml-auto block"
            wrapperClassName="w-max h-max"
          />
        );
      }

      return (
        <button
          className={clsx(`flex items-center h-6`)}
          style={
            {
              '--favourite-icon-color': props.iconColor || '#000000',
              '--favourite-icon-color-active':
                props.iconColorActive || '#5d0024',
              '--favourite-icon-color-hover': props.iconColorHover || '#5d0024',
              '--favourite-icon-hover-fill-color':
                props.iconColorHoverFill || '#5d0024'
            } as React.CSSProperties
          }
          onClick={handleClick}
          data-testid="favourites-icon"
          onMouseEnter={() => setIsFavoriteIconHovered(true)}
          onMouseLeave={() => setIsFavoriteIconHovered(false)}
        >
          {renderFavoriteIcon(
            isActive
              ? '[&_path]:fill-[var(--favourite-icon-color-active)]'
              : '[&_path]:fill-[var(--favourite-icon-color)] hover:[&_path]:fill-[var(--favourite-icon-color-hover)]',
            '[&_path]:fill-[var(--favourite-icon-hover-fill-color)]'
          )}

          {props.label && (
            <span className={twMerge('ml-2', props?.labelClassName)}>
              {props.label}
            </span>
          )}
        </button>
      );
    };

    return View;
  }, [isActive, isParentHovered, handleClick, isLoading]);

  return {
    FavButton
  };
};

export default useFavButton;
